import { ConfirmOrderPaid } from '../../../requests/carts';
import { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

declare global {
  interface Window {
    ConektaCheckoutComponents: any;
  }
}

export const ConektaCheckout = ({
  checkoutRequestId,
  onPaymentSuccess,
}: {
  checkoutRequestId: string;
  onPaymentSuccess: () => void;
}) => {
  const navigate = useNavigate();
  const [userExited, setUserExited] = useState(false);
  const CONEKTA_PUBLIC_KEY = import.meta.env.VITE_CONEKTA_PUBLIC_API_KEY;

  const configureWindow = useCallback(() => {
    const element = document.getElementById('example-conekta-checkout');
    if (element && element.childNodes.length > 0) {
      return;
    }

    window.ConektaCheckoutComponents.Integration({
      config: {
        locale: 'es',
        publicKey: CONEKTA_PUBLIC_KEY,
        targetIFrame: '#example-conekta-checkout',
        checkoutRequestId: checkoutRequestId,
      },
      callbacks,
      options,
    });
  }, [checkoutRequestId]);

  useEffect(() => {
    if (!userExited) {
      configureWindow();
    }
  }, [checkoutRequestId, userExited]);

  const options = {
    backgroundMode: 'lightMode',
    colorPrimary: '#081133',
    colorText: '#585987',
    colorLabel: '#585987',
    inputType: 'flatMode',
  };

  const callbacks = {
    onGetInfoSuccess: (loadingTime: any) => {},

    onFinalizePayment: async (order: any) => {
      await ConfirmOrderPaid(order, order.id)
        .then(() => {
          onPaymentSuccess();
          navigate('/checkout/redirect');
        })
        .catch((error) => {
          console.log('error: ', error);
        });
    },

    onErrorPayment: (error: any) => {
      console.log('error en pago: ', error);
    },

    // Stop auto-redirect if user exits manually
    onExit: () => {
      setUserExited(true);
      navigate('/checkout/redirect');
    },
  };

  return (
    <div
      id="example-conekta-checkout"
      style={{
        position: 'fixed',
        top: '50px',
        left: 0,
        width: '100vw',
        height: 'calc(100vh - 50px)',
        backgroundColor: '#ffffff',
        zIndex: 9999,
      }}
    ></div>
  );
};
