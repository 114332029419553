import { Divider, MenuItem, TextField } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import { useEffect, useState, useRef, useCallback } from 'react';
import DefaultImage from '../../images/defaultImage.png';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.min.css';
import TGDropzone from '../TgDropzone';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SaveIcon from '@mui/icons-material/Save';
import { toCurrency } from '../../utils/currency';
import { SectionEntity } from 'libs/domain/src/lib/entities/Section';

const classes = {
  cropContainer: {
    position: 'relative',
    height: '100%',
    width: '100%',
    background: '#fff',
  },
};

const styleCard = {
  width: 350,
  height: 400,
  maxHeight: 450,
  borderRadius: '10px',
  boxShadow: '0px 0px 10px 0px #000000',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

interface SectionLayoutPreviewProps {
  image: File | null;
  onCancel: () => void;
  onSave: (file: File) => void;
  currentImage?: string | null;
  section?: SectionEntity | null;
}

export default function SectionLayoutPreview({
  image,
  section,
  currentImage,
  // functions
  onCancel,
  onSave,
}: SectionLayoutPreviewProps) {
  const [layoutImage, setLayoutImage] = useState<string | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [showPreview, setShowPreview] = useState(false);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [croppedImageFile, setCroppedImageFile] = useState<File | null>(null);
  const cropperRef = useRef<ReactCropperElement>(null);

  const handleChangeImage = () => {
    setLayoutImage(null);
    setImageSrc(null);
    setCroppedImage(null);
    setShowPreview(false);
  };

  const onReceiveFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImageSrc(reader.result as string);
      setShowPreview(false);
    };
    reader.readAsDataURL(file);
  };

  const getCroppedImage = useCallback(() => {
    if (cropperRef.current && cropperRef.current.cropper) {
      const cropper = cropperRef.current.cropper;
      const canvas = cropper.getCroppedCanvas();
      if (canvas) {
        const dataUrl = canvas.toDataURL('image/jpeg');
        setCroppedImage(dataUrl);
        setLayoutImage(dataUrl);

        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], 'cropped.jpg', {
              type: 'image/jpeg',
            });
            setCroppedImageFile(file);
          }
        }, 'image/jpeg');
        setShowPreview(true);
      }
    }
  }, []);

  const handleSave = async () => {
    if (croppedImageFile) {
      onSave(croppedImageFile);
    }
  };

  useEffect(() => {
    if (currentImage) {
      setLayoutImage(currentImage);
      setShowPreview(true);
    }
  }, [currentImage]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box>
        <Typography
          variant="h5"
          sx={{
            color: 'grey',
            fontSize: '1rem',
            mb: 2,
          }}
        >
          Asi se lucirá tu Sección en la selección de boletos
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignSelf: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '750px',
            height: '70%',
          }}
        >
          <Card sx={styleCard}>
            {showPreview && (
              <CardMedia
                sx={{ height: '100%', borderRadius: '5px 5px 0px 0px' }}
                image={layoutImage || croppedImage || DefaultImage}
              />
            )}
            {!showPreview && imageSrc && (
              <Box sx={classes.cropContainer}>
                <Cropper
                  src={imageSrc}
                  height={400}
                  width={350}
                  autoCropArea={3 / 4}
                  guides
                  viewMode={1}
                  background={false}
                  responsive
                  checkOrientation={false}
                  dragMode="move"
                  ref={cropperRef}
                  minContainerHeight={400}
                  minContainerWidth={350}
                />
              </Box>
            )}
            {!croppedImage && !imageSrc && !layoutImage && (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <TGDropzone
                  containerHeight={400}
                  file={null}
                  onReceiveFile={onReceiveFile}
                />
              </Box>
            )}
          </Card>
          <Card
            sx={{
              width: {
                xs: '45%',
                sm: '45%',
                md: '50%',
                lg: '45%',
                xl: '45%',
              },
              borderRadius: '10px',
              overflowY: 'auto',
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  sx={{
                    alignSelf: 'center',
                    marginBottom: '20px',
                    textTransform: 'uppercase',
                    fontSize: '15px',
                    fontWeight: '900',
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  Selecciona tus boletos
                </Typography>
                <Divider />
              </Box>
              {/* Date */}
              <Box sx={{ marginTop: '30px' }}>
                <Typography
                  sx={{
                    alignSelf: 'center',
                    marginBottom: '20px',
                    textTransform: 'uppercase',
                    fontSize: '15px',
                    fontWeight: '900',
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  {format(new Date(), "dd 'de' MMMM yyyy", {
                    locale: es,
                  })}
                </Typography>
              </Box>
              {/* Date */}
              {/* Section */}
              <Box sx={{ marginTop: '30px' }}>
                {section && (
                  <TextField
                    fullWidth
                    size="medium"
                    id="select-section"
                    label="Selecciona tu sección"
                    defaultValue={1}
                    select
                  >
                    <MenuItem value={1}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Box
                          component="div"
                          sx={{
                            m: 1,
                            borderRadius: 1,
                            width: 20,
                            height: 20,
                            backgroundColor: `${section.color}`,
                          }}
                        />
                        <Box>
                          {section.name} - {section.type} -
                          {toCurrency(section.price)}
                        </Box>
                      </Box>
                    </MenuItem>
                  </TextField>
                )}
              </Box>
              {/* Section */}
            </CardContent>
            <CardActions
              disableSpacing
              sx={{
                height: '150px',
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'column',
              }}
            >
              <Button
                size="large"
                fullWidth
                onClick={() => null}
                disabled
                variant="outlined"
              >
                <AddShoppingCartIcon />
                Agregar al carrito
              </Button>
            </CardActions>
          </Card>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            m: 2,
          }}
        >
          {(croppedImage || layoutImage || imageSrc) && (
            <Button
              sx={{
                width: '100%',
                minHeight: '35px',
                background: '#444E6A',
                borderRadius: '10px',
                color: '#FFFFFF',
                fontWeight: 600,
                letterSpacing: '1px',
                fontFamily: 'Poppins',
                textTransform: 'capitalize',
                fontSize: '1rem',
                '&:hover': {
                  backgroundColor: '#FFFFFF',
                  color: '#444E6A',
                },
                mb: 2,
              }}
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleChangeImage}
              startIcon={<AddPhotoAlternateIcon />}
            >
              Cambiar imagen
            </Button>
          )}

          {imageSrc && (
            <Button
              sx={{
                width: '100%',
                minHeight: '35px',
                background: '#FFFFFF',
                borderRadius: '10px',
                color: '#444E6A',
                fontWeight: 600,
                letterSpacing: '1px',
                fontFamily: 'Poppins',
                textTransform: 'capitalize',
                border: '2px solid #444E6A',
                fontSize: '1rem',
                '&:hover': {
                  backgroundColor: '#444E6A',
                  border: '2px solid #444E6A',
                  color: '#FFFFFF',
                },
                mb: 2,
              }}
              variant="contained"
              color="info"
              onClick={() => {
                if (showPreview) {
                  setShowPreview(false);
                } else {
                  getCroppedImage();
                }
              }}
              size="small"
              startIcon={showPreview ? <EditIcon /> : <VisibilityIcon />}
            >
              {showPreview ? 'Seguir editando' : 'Pre visualizar'}
            </Button>
          )}

          {croppedImage && showPreview && (
            <Button
              sx={{
                width: '100%',
                minHeight: '35px',
                background: '#444E6A',
                borderRadius: '10px',
                color: '#FFFFFF',
                fontWeight: 600,
                letterSpacing: '1px',
                fontFamily: 'Poppins',
                textTransform: 'capitalize',
                fontSize: '1rem',
                '&:hover': {
                  backgroundColor: '#FFFFFF',
                  color: '#444E6A',
                },
                mb: 2,
              }}
              variant="contained"
              color="success"
              size="small"
              onClick={handleSave}
              startIcon={<SaveIcon />}
            >
              Guardar
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
